import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import FadeIn from "react-fade-in"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StandardPage = ({ intl, data }) => {
  let strapiNode
  switch (intl.locale) {
    case "en":
      strapiNode = data.allStrapiEnPage.edges[0].node
      break
    case "ko":
      strapiNode = data.allStrapiKoPage.edges[0].node
      break
    case "cn":
      strapiNode = data.allStrapiCnPage.edges[0].node
      break
    default:
      break
  }

  const pageContent = strapiNode.contentRow.map(row => {
    const rowItems = row.item.map(item => {
      if (item.paragraph && item.image) {
        return (
          <Fragment key={item.id}>
            <h2>{item.paragraph.heading}</h2>
            <p
              className="multiline-text"
              dangerouslySetInnerHTML={{ __html: item.paragraph.text }}
            />
            <div>
              <Img fluid={item.image.childImageSharp.fluid} />
            </div>
          </Fragment>
        )
      } else {
        if (item.paragraph) {
          return (
            <Fragment key={item.id}>
              <h2>{item.paragraph.heading}</h2>
              <p
                className="multiline-text"
                dangerouslySetInnerHTML={{ __html: item.paragraph.text }}
              />
            </Fragment>
          )
        } else if (item.image) {
          return (
            <div key={item.id}>
              <Img fluid={item.image.childImageSharp.fluid} />
            </div>
          )
        } else {
          return <></>
        }
      }
    })

    if (rowItems.length === 0) {
      return <></>
    } else if (rowItems.length === 2) {
      return (
        <Row key={row.id}>
          <Col lg="8">{rowItems[0]}</Col>
          <Col lg="4" className="my-auto d-none d-lg-block">
            {rowItems[1]}
          </Col>
        </Row>
      )
    } else {
      const rowColumns = rowItems.map(rowItem => {
        return (
          <Col key={`c${rowItem.id}`} className="my-auto d-none d-lg-block">
            {rowItem}
          </Col>
        )
      })
      return <Row key={row.id}>{rowColumns}</Row>
    }
  })

  return (
    <Layout page="standard">
      <SEO lang={intl.locale} title={`${strapiNode.title}`} />
      <style>
        {`
        .multiline-text{
           white-space: pre-line;
        }
      `}
      </style>
      <Container fluid>
        <FadeIn>
          <h1 className="text-center">{strapiNode.title}</h1>
          <p className="text-center">{strapiNode.subtitle}</p>
          {pageContent}
        </FadeIn>
      </Container>
    </Layout>
  )
}

export default injectIntl(StandardPage)

export const query = graphql`
  query StandardPageQuery($url: String!) {
    allStrapiKoPage(filter: { url: { eq: $url } }) {
      edges {
        node {
          url
          title
          subtitle
          contentRow {
            id
            item {
              id
              paragraph {
                heading
                text
              }
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiEnPage(filter: { url: { eq: $url } }) {
      edges {
        node {
          url
          title
          subtitle
          contentRow {
            id
            item {
              id
              paragraph {
                heading
                text
              }
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiCnPage(filter: { url: { eq: $url } }) {
      edges {
        node {
          url
          title
          subtitle
          contentRow {
            id
            item {
              id
              paragraph {
                heading
                text
              }
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
